import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Hr, Button, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Horizon Line Yacht Rentals
			</title>
			<meta name={"description"} content={"Embark on an adventure - the horizon line where the sea meets the sky"} />
			<meta property={"og:title"} content={"Horizon Line Yacht Rentals"} />
			<meta property={"og:description"} content={"Embark on an adventure - the horizon line where the sea meets the sky"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
		</Helmet>
		<Components.Header1>
			<Override slot="link1" display="block" href="/about" />
			<Override slot="box1" />
		</Components.Header1>
		<Section
			padding="130px 0 140px 0"
			background="linear-gradient(0deg,rgba(7, 2, 14, 0.85) 100%,rgba(0,0,0,0) 100%,#5a20ce 100%),#111828 url(https://uploads.quarkly.io/65e71754926d910020edb194/images/1-1%20%281%29.jpg?v=2024-03-05T16:29:20.537Z) 0% 0%/cover"
			color="#000000"
			sm-padding="80px 0 90px 0"
			md-padding="80px 0 80px 0"
			quarkly-title="Statistics-8"
		>
			<Box min-width="100px" min-height="100px" padding="0px 600px 0px 0px" lg-padding="0px 0 0px 0px">
				<Text margin="0px 0px 10px 0px" color="#ffe799" text-transform="uppercase" font="--lead">
					Our Journey Begins
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" sm-font="normal 600 36px/1.2 --fontFamily-sans">
					About Us
				</Text>
				<Text margin="25px 0px 0px 0px" font="--lead" color="--light">
					Our fleet, though diverse, carries a common thread – a promise to deliver experiences that last long after the journey ends.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 50px 0" sm-padding="40px 0 30px 0" quarkly-title="Statistics-11">
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1440px"
			/>
			<Box width="100%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline1">
					The Essence of Horizon Line
				</Text>
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					At the heart of Horizon Line Yacht Rentals is a passion for the boundless beauty of the sea and the stories that unfold on its waves. Our goal is not just to provide a boat but to offer a path to adventures that resonate in the soul of every sailor. It’s about connecting with the rhythm of the waters under the banner of freedom and discovery.
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					With Horizon Line Yacht Rentals, your journey becomes more than just time spent on the water – it’s an opportunity to create your own story, to be part of a community that values the timeless allure of the sea. Let us be the compass that leads you to new horizons and memories that will be treasures of your life.
				</Text>
			</Box>
		</Section>
		<Section
			padding="100px 0 120px 0"
			background="#111828"
			color="#000000"
			sm-padding="80px 0 90px 0"
			quarkly-title="Statistics-5"
		>
			<Text margin="0px 0px 0px 0px" color="--light" font="--headline2">
				Features
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				lg-flex-direction="column"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 25px 0px 0px"
					width="50%"
					lg-width="100%"
					lg-padding="0px 0 30px 0px"
				>
					A diverse fleet for every explorer: Our selection ranges from nimble motorboats to elegant yachts, perfectly suited for your maritime adventure.
					<br />
					<br />
					{" "}Guidance on the waves. Benefit from the knowledge of seasoned sailors who will not only navigate safely but also enrich your journey with maritime tales.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 0px 0px 25px"
					width="50%"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					A journey tailored for you: We excel in personalized experiences, from serene solo journeys to joyful gatherings on the waves.
					<br />
					<br />
					Sustainable development at sea. Focusing on the present, we think about the future, applying methods that ensure the careful treatment of the marine environment.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="normal 500 20px/1.5 --fontFamily-sansVerdana"
				border-color="#dca654"
				text-align="center"
			>
				Horizon Line Yacht Rentals
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				text-align="center"
			>
				<Box min-width="100px" min-height="100px" width="100%">
					<Text margin="0 0px 0px 0px" font="--headline3" color="#050c32" sm-font="normal 600 30px/1.2 --fontFamily-serifGeorgia">
						Every Journey is a Story Worth Telling
					</Text>
					<Button
						margin="1rem 0px 0px 0px"
						background="--color-secondary"
						type="link"
						text-decoration-line="initial"
						href="/contacts"
					>
						Contacts
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Price-19" background="#111828">
			<Override slot="SectionContent" color="--light" />
			<Text margin="0px 0px 20px 0px" font="normal 700 46px/1.2 --fontFamily-sans" text-align="center">
				SERVICES
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--lightD1"
				md-margin="0px 0px 50px 0px"
			>
				Horizon Line Yacht Rentals offers a range of services designed to make your time on the sea unforgettable. Our personalized and quality-focused offerings ensure that every moment spent on the water is one of discovery, relaxation, and adventure.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 50px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Yacht and Motorboat Fleet
					</Text>
					<Box min-width="100px" min-height="100px">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--lightD1">
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Elegant Yachts
									<br />
								</Strong>
								Our yachts are perfect for those seeking a blend of luxury and adventure. They offer ideal conditions for serene relaxation or festive gatherings.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--lightD1">
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Fast Motorboats
									<br />
								</Strong>
								For the adventurous souls looking to explore the coastline, our motorboats offer speed, maneuverability, and the freedom to discover hidden coves and beaches.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						/>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Personalized Journeys
					</Text>
					<Box min-width="100px" min-height="100px">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--lightD1">
								<Strong>
									Customized Adventures:{" "}
								</Strong>
								each journey is as unique as our clients. Whether you seek a peaceful solo expedition or a vibrant water party, we tailor the trip to your desires.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--lightD1">
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Sunset Cruises
									<br />
								</Strong>
								Experience the magic of the horizon at sunset with our specially designed evening cruises, perfect for romantic getaways or solitary reflections.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 25px/1.2 --fontFamily-sans">
						Experienced Crew and Guidance
					</Text>
					<Box min-width="100px" min-height="100px" color="--lightD1">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--lightD1">
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Qualified Pilots
									<br />
								</Strong>
								Our team members are seasoned sailors, ready to share their maritime knowledge and ensure a safe and enjoyable journey.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--lightD1">
								<Strong>
									Customized Routes.
								</Strong>
								{" "}Take advantage of our captains' expertise, who can design personalized routes showcasing the beauty and mystery of the ocean.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer23 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65cddc290072400020ea2467"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});